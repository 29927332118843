import React, { useContext } from 'react'
import { ShoppingCartIcon } from '@heroicons/react/20/solid'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { AppContext } from '../context'


export default function Products() {
  const bannerImg = 'http://images.waxcarvers.com.s3-website-eu-west-1.amazonaws.com/waxcarvers.png'
  const attributes = {
    title: 'WaxCarvers Products',
    description: 'Buy Wax Carving Classes'
  }
  const { state: contextState } = useContext(AppContext)

  return (
    <Layout full>
      <Helmet>
        <title>{attributes.title} ¦ Wax Carvers</title>
        <meta name="description" content={attributes.description} />
        <meta property="og:title" content={`${attributes.title} | Wax Carvers`} />
        <meta property="og:image" content={bannerImg} />
        <meta property="og:url" content={`https://www.waxcarvers.com/advent-calendar-2022`} />
        <meta property="og:description" content={attributes.description} />

        <meta itemProp="name" content={`${attributes.title} | Wax Carvers`} />
        <meta itemProp="description" content={attributes.description} />

        <meta property="twitter:title" content={`${attributes.title} | Wax Carvers`} />
        <meta property="twitter:description" content={attributes.description} />
        <meta property="twitter:card" content={bannerImg} />
        <meta property="twitter:image:src" content={bannerImg} />
      </Helmet>

      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {contextState && contextState.products.map((product) => (
          <li
            key={product.id}
            className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
          >
            <div className="flex flex-1 flex-col p-8">
              <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" src={product.images[0]} alt="" />
              <h3 className="mt-6 text-sm font-medium text-gray-900">{product.name}</h3>
              <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dt className="sr-only">Description</dt>
                <dd className="text-sm text-gray-500">{product.description}</dd>
              </dl>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="-ml-px flex w-0 flex-1">
                  <a
                    href={`tel:${product.telephone}`}
                    className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                  >
                    <ShoppingCartIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="ml-3">Add to cart</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Layout>

  )
}
